<template>
	<div>
        <header id="header" class="header">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="text-container">
                            <h1 class="h1-large">
                                <span id="typewriter"></span>
                                <br>
                                <font color="blue">website</font>
                            </h1>
                            <p class="p-large">Undang keluarga dan teman untuk hadir di acara spesial kamu, dengan ondangan website</p>
                            <a class="btn-solid-lg page-scroll" href="#features">Lihat Detail</a>
                            <a class="btn-solid-lg page-scroll secondary" href="#demo">Live Demo</a>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="image-container">
                            <img class="img-fluid" src="images/header-smartphone.png" alt="alternative">
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <div class="basic-1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <p>ondangan.<font color="blue">website</font><br> "desain yang responsive, simple &amp; user friendly"</p>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
	import Typewriter from 'typewriter-effect/dist/core'
    export default {
        name: 'Header',
        mounted() {

            // Typewritter
            const typewriter = new Typewriter('#typewriter', {
				autoStart: true,
				delay:20,
				loop: true,
				cursor: ''
			});
			typewriter
				.changeDelay(80)
				.changeDeleteSpeed(10)
				
				.typeString('reservasi')
				.pauseFor(200)
				.deleteChars(9)
				
				.typeString('buku tamu')
				.pauseFor(200)
				.deleteChars(9)

				.typeString('gallery')
				.pauseFor(200)
				.deleteChars(7)
				
				.typeString('foto')
				.pauseFor(200)
				.deleteChars(4)
				
				.typeString('video')
				.pauseFor(200)
				.deleteChars(5)
				
				.typeString('love story')
				.pauseFor(200)
				.deleteChars(10)

                .typeString('nikahan')
				.pauseFor(200)
				.deleteChars(8)

                .typeString('live Zoom')
				.pauseFor(200)
				.deleteChars(9)
                
                .typeString('live Instagram')
				.pauseFor(200)
				.deleteChars(14)
                
                .typeString('live YouTube')
				.pauseFor(200)
				.deleteChars(12)
				
                .typeString('e-invitation')
				.pauseFor(200)
				.deleteChars(12)

                .typeString('undangan digital')
				.pauseFor(200)
				.deleteChars(16)
                
                .typeString('undangan online')
				.pauseFor(200)
				.deleteChars(15)

				.pauseFor(400)
                .changeCursor(' ')
				.pasteString('<span style="color:#eb427e;">ondangan</span>')
                .pauseFor(2000)
				.start();
        }
    }
</script>