import Vue from 'vue'
import VueRouter from 'vue-router'

// Init view
import Home from '@/components/Home.vue'
import Shortlink from '@/module/Shortlink.vue'

Vue.use(VueRouter);

const router = new VueRouter({
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'default',
            component: Home
        },
        {
            path: '/shortlink',
            name: 'Shortlink',
            component: Shortlink
        }
    ]
})

export default router