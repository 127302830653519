<template>
    <div id="demo" class="slider-1 bg-gray">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2 class="h2-heading">Demo Ondangan</h2>
                    <p style="margin-top:-40px; padding-bottom:30px;">Tersedia 7 varian template</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="slider-container">
                        <div ref="slider" class="swiper-container card-slider">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body" v-on:click="viewDemo('demo7')" style="cursor:pointer;">
                                            <img src="images/demo7.png" alt="alternative" width="200px;">
                                            <p class="testimonial-author"><br>Katineung - Gold</p>
                                            <small v-if="showCounter">Dilihat {{ counter.demo7 }} kali</small>
                                            <small v-else>Klik untuk melihat demo</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body" v-on:click="viewDemo('demo6')" style="cursor:pointer;">
                                            <img src="images/demo6.png" alt="alternative" width="200px;">
                                            <p class="testimonial-author"><br>Katineung - Pink</p>
                                            <small v-if="showCounter">Dilihat {{ counter.demo6 }} kali</small>
                                            <small v-else>Klik untuk melihat demo</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body" v-on:click="viewDemo('demo5')" style="cursor:pointer;">
                                            <img src="images/demo5.png" alt="alternative" width="200px;">
                                            <p class="testimonial-author"><br>Instagram Style - Black</p>
                                            <small v-if="showCounter">Dilihat {{ counter.demo5 }} kali</small>
                                            <small v-else>Klik untuk melihat demo</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body" v-on:click="viewDemo('demo4')" style="cursor:pointer;">
                                            <img src="images/demo4.png" alt="alternative" width="200px;">
                                            <p class="testimonial-author"><br>Instagram Style - White</p>
                                            <small v-if="showCounter">Dilihat {{ counter.demo4 }} kali</small>
                                            <small v-else>Klik untuk melihat demo</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body" v-on:click="viewDemo('demo3')" style="cursor:pointer;">
                                            <img src="images/demo3.png" alt="alternative" width="200px;">
                                            <p class="testimonial-author"><br>Premiere - Gold</p>
                                            <small v-if="showCounter">Dilihat {{ counter.demo3 }} kali</small>
                                            <small v-else>Klik untuk melihat demo</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body" v-on:click="viewDemo('demo2')" style="cursor:pointer;">
                                            <img src="images/demo2.png" alt="alternative" width="200px;">
                                            <p class="testimonial-author"><br>Premiere - Blue</p>
                                            <small v-if="showCounter">Dilihat {{ counter.demo2 }} kali</small>
                                            <small v-else>Klik untuk melihat demo</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="card">
                                        <div class="card-body" v-on:click="viewDemo('demo1')" style="cursor:pointer;">
                                            <img src="images/demo1.png" alt="alternative" width="200px;">
                                            <p class="testimonial-author"><br>Premiere - Pink</p>
                                            <small v-if="showCounter">Dilihat {{ counter.demo1 }} kali</small>
                                            <small v-else>Klik untuk melihat demo</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-button-next"></div>
                            <div class="swiper-button-prev"></div>
                        </div>
                    </div>
                    <!-- <div class="text-center pt-5">
                        <div class="button-wrapper">
                            <a class="btn-solid-reg page-scroll secondary" href="#demo">Lihat semua template</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import Axios from 'axios'
    import { database } from '@/config/Firebase'
    export default {
        name: 'Demo',
        data() {
            return {
                counter: []
            }
        },
        props: {
            showCounter: String
        },
        created() {
			var api = 'https://high-extension-176903.firebaseio.com/demo-counter.json';
			Axios.get(api)
				.then(response => {
					this.counter = response.data
				})
		},
        methods: {
            viewDemo: function(demo) {
                let current = this.counter[demo]
                let counter = current += 1
                database.ref('demo-counter/' + demo).set(counter);
                this.counter[demo] = counter
                var url = 'https://'+demo+'.menujunikah.com';
                window.open(url, '_blank', 'noopener');
            }
        },
        mounted() {
            // Card Slider - Swiper
            let slider = this.$refs.slider
            var cardSlider = new Swiper(slider, {
                // autoplay: {
                //     delay: 4000,
                //     disableOnInteraction: false
                // },
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                slidesPerView: 3,
                spaceBetween: 70,
                breakpoints: {
                    // when window is <= 767px
                    767: {
                        slidesPerView: 1
                    },
                    // when window is <= 991px
                    991: {
                        slidesPerView: 2,
                        spaceBetween: 40
                    }
                }
            });
        }
    }
</script>

