import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'

let config = {
	apiKey: "AIzaSyBltRCxJrKzOcCI9FsYs06OkoK5UpMybeo",
	authDomain: "high-extension-176903.firebaseapp.com",
	databaseURL: "https://high-extension-176903.firebaseio.com",
	projectId: "high-extension-176903",
	storageBucket: "high-extension-176903.appspot.com",
	messagingSenderId: "178989843094",
	appId: "1:178989843094:web:1e0aeb64e46b451c6238b7"
}

fb.initializeApp(config);

// Export auth
export const firebase = fb;

// Realtime Database
export const database = fb.database()

// Cloud Firestore
export const firestore = fb.firestore()

// Default timestamp
export const datetime = fb.firestore.FieldValue.serverTimestamp()