<template>
	<div>
        <div id="pricing" class="cards-2">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h2 class="h2-heading">Harga spesial hanya di bulan ini</h2>
                        <p class="p-heading">Pilih paket terbaik, dan dapatkan semua fiturnya dalam 1 website undangan pernikahan kamu</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="card-title">PAKET BASIC</div>
                                <div class="price"><span class="value">90</span><span class="currency">K</span></div>
                                <div class="frequency">1 month expired</div>
                                <p>Cocok untuk share undangan kekinian berupa link website</p>
                                <ul class="list-unstyled li-space-lg">
                                    <li class="media text-primary font-weight-bold">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Unlimited nama tamu</div>
                                    </li>
                                    <li class="media">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">3x revisi konten</div>
                                    </li>
                                    <li class="media">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Website akan aktif selama 1 bulan, terhitung sejak tanggal website di terbitkan</div>
                                    </li>
                                </ul>
                                <div class="button-wrapper">
                                    <a 
                                        v-on:click="setForm"
                                        class="btn-solid-reg popup-form-order"
                                        href="#lightbox-order"
                                        data-package="Basic">Pesan Paket Basic
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card" style="padding-top: 22px;">
                            <div class="card-body">
                                <div class="card-title">PAKET PRO</div>
                                <span class="badge bg-success text-white" style="padding: 10px; position: absolute;top: 15px;left: -15px;font-size: 20px;">
                                    RECOMMENDED
                                </span>
                                <div class="price">
                                    <span class="value">160</span><span class="currency">K</span>
                                </div>
                                <div class="frequency">hemat 10% <span class="text-success" style="font-size:30px;">144 K</span></div>
                                <p>Jadikan momen undangan terbaik untuk kamu dan pasangan</p>
                                <ul class="list-unstyled li-space-lg">
                                    <li class="media">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Unlimited nama tamu</div>
                                    </li>
                                    <li class="media text-primary font-weight-bold">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Unlimited revisi konten</div>
                                    </li>
                                    <li class="media text-primary font-weight-bold">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Auto reminder WhatsApp <br>untuk 50 nomor</div>
                                    </li>
                                    <li class="media">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Website akan aktif selama 3 bulan, terhitung sejak tanggal website di terbitkan</div>
                                    </li>
                                </ul>
                                <div class="button-wrapper">
                                    <a 
                                        v-on:click="setForm"
                                        class="btn-solid-reg popup-form-order"
                                        href="#lightbox-order"
                                        data-package="Pro">Pesan Paket Pro
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="card-title">PAKET ADVANCED</div>
                                <div class="price"><span class="value">250</span><span class="currency">K</span></div>
                                <div class="frequency">priority package</div>
                                <p>Miliki kenangan cerita perjalanan kamu dan pasangan</p>
                                <ul class="list-unstyled li-space-lg">
                                    <li class="media">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Unlimited nama tamu</div>
                                    </li>
                                    <li class="media">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Unlimited revisi konten</div>
                                    </li>
                                    <li class="media text-primary font-weight-bold">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Auto reminder WhatsApp Unlimited</div>
                                    </li>
                                    <li class="media text-primary font-weight-bold">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Auto blast WhatsApp</div>
                                    </li>
                                    <li class="media text-primary font-weight-bold">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Link live Zoom, YouTube, Instagram</div>
                                    </li>
                                    <li class="media">
                                        <i class="fas fa-chevron-right"></i><div class="media-body">Website akan selalu aktif dan bisa terus di akses selama domain <b>ondangan.website</b> masih aktif</div>
                                    </li>
                                </ul>
                                <div class="button-wrapper">
                                    <a 
                                        v-on:click="setForm"
                                        class="btn-solid-reg popup-form-order"
                                        href="#lightbox-order"
                                        data-package="Advanced">Pesan Paket Advanced
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="download" class="basic-5">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="image-container">
                            <img class="img-fluid" src="images/conclusion-smartphone.png" alt="alternative">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="text-container">
                            <p>Buat undangan website kekinian dengan ondangan.<span class="text-primary">website</span><br>Pesan sekarang</p>
                            <a class="btn-solid-lg secondary" href="https://wa.me/6282240676507?text=Hi%20admin,%20saya%20tertarik%20untuk%20order%20ondangan.website" target="_blank" rel="noopener">
                                <i class="fab fa-whatsapp"></i>Order via WhatsApp
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="lightbox-order" class="lightbox-basic zoom-anim-dialog mfp-hide">
            <div class="row">
                <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
                <div class="col-lg-12">
                    <div class="text-container" v-if="beforeSubmit">
                        <h3>Form Order</h3>
                        <hr>
                        <p>Silahkan isi form berikut </p>
                        <form @submit.prevent="sendwhatsApp">
                            <div class="row">
                                <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                        <label for="package_name">Nama Paket <span class="text-danger">*</span></label>
                                        <select 
                                            v-model="package_name"
                                            v-bind:class="!package_name && is_required_package_name ? 'is-invalid' : ''" 
                                            name="package_name" id="package_name" class="form-control">
                                            <option value="">- Choose One -</option>
                                            <option value="Basic">Paket Basic</option>
                                            <option value="Pro">Paket Pro</option>
                                            <option value="Advanced">Paket Advanced</option>
                                        </select>
                                        <small class="form-text text-danger" v-if="!package_name">{{ is_required_package_name }}</small>
                                    </div>
                                    <div class="form-group">
                                        <label for="template_name">Pilih Template <span class="text-danger">*</span></label>
                                        <select 
                                            v-model="template_name" 
                                            v-bind:class="!template_name && is_required_template_name ? 'is-invalid' : ''"
                                            name="template_name" id="template_name" class="form-control">
                                            <option value="">- Choose One -</option>
                                            <option value="Katineung - Gold">Katineung - Gold</option>
                                            <option value="Katineung - Pink">Katineung - Pink</option>
                                            <option value="Instagram Style - Black">Instagram Style - Black</option>
                                            <option value="Instagram Style - White">Instagram Style - White</option>
                                            <option value="Premiere - Gold">Premiere - Gold</option>
                                            <option value="Premiere - Blue">Premiere - Blue</option>
                                            <option value="Premiere - Pink">Premiere - Pink</option>
                                        </select>
                                        <small class="form-text text-danger" v-if="!template_name">{{ is_required_template_name }}</small>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <div class="form-group">
                                        <label for="subdomain">Link URL / Sub Domain <span class="text-danger">*</span></label>
                                        <input 
                                            v-model="subdomain" 
                                            v-bind:class="!subdomain && is_required_subdomain ? 'is-invalid' : ''" 
                                            @keydown.space.prevent
                                            type="text" id="subdomain" name="subdomain" class="form-control" autocomplete="off" placeholder="Input here">
                                        <p class="form-text" v-if="subdomain">
                                            <small>The website url will be</small><br>
                                            <span class="text-success">
                                                https://{{ subdomain.toLowerCase() }}.ondangan.website
                                            </span>
                                        </p>
                                        <small class="form-text text-danger" v-if="!subdomain">{{ is_required_subdomain }}</small>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <button type="submit" class="btn-solid-reg">Submit</button> &nbsp;
                            <button v-on:click="clear" type="reset" class="btn-outline-reg">Reset</button>
                        </form>
                    </div>
                    <div class="text-container" v-else>
                        <div v-html="lightboxHtml"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pricing',
        data() {
            return {
                beforeSubmit: true,
                lightboxHtml: '',
                package_name: '',
                template_name: '',
                subdomain: '',
                is_required_package_name: '',
                is_required_template_name: '',
                is_required_subdomain: '',
            }
        },
        methods: {
            setForm: function(e) {
                this.beforeSubmit = true
                this.package_name = e.currentTarget.getAttribute('data-package');
            },
            sendwhatsApp() {
                if (!this.package_name) {
                    this.is_required_package_name = 'Package name is required'
                }
                if (!this.template_name) {
                    this.is_required_template_name = 'Please choose at least 1 template'
                }
                if (!this.subdomain) {
                    this.is_required_subdomain = 'Subdomain is required'
                }

                if (this.package_name && this.template_name && this.subdomain) {
                    var message = 'Halo min, saya ingin order Paket '+this.package_name+' di ondangan.website %0a'+
                                    'Template : '+this.template_name+' %0a'+
                                    'Sub Domain : '+this.subdomain;
                    var url = 'https://wa.me/6282240676507?text='+message
                    window.open(url, '_blank', 'noopener')
                    
                    this.clear()
                    this.beforeSubmit = false
                    this.lightboxHtml = '<p class="text-center justify-content-center">Thanks for your order :)</p>';
                }
            },
            clear() {
                this.package_name = ''
                this.template_name = ''
                this.subdomain = ''
                this.is_required_package_name = ''
                this.is_required_template_name = ''
                this.is_required_subdomain = ''
            },
        },
        mounted() {
            $('.popup-form-order').magnificPopup({
                type: 'inline',
                fixedContentPos: true,
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-slide-bottom',
                callbacks: {
                    beforeOpen: function() {
                        let self = this.st.el
                        $('#package_name').val(self.data('package'));
                    },
                    // open: function () {
                    //     alert($(this).attr('data-package'));
                    //     // $.magnificPopup.instance.close = function () {
                    //     //     $('#form-order').trigger("reset");
                    //     //     $.magnificPopup.proto.close.call(this);
                    //     // };
                    // }
                }
            });
        }
    }
</script>
