import Vue from 'vue'
import App from './App.vue'
import Router from './router'

import VueToast from 'vue-toast-notification';
import VueLetterAvatar from 'vue-letter-avatar';
import 'vue-toast-notification/dist/theme-default.css';
import './registerServiceWorker'

Vue.config.productionTip = false

Vue.use(VueToast);
Vue.use(VueLetterAvatar);

new Vue({
	'router': Router, 
	'data': {}, 
	'methods': {},
	render: h => h(App),
}).$mount('#app');
