<template>
	<div>
        <div id="features" class="cards-1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-image">
                                <img class="img-fluid" src="images/features-icon-5.svg" alt="alternative">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">Efektif & Efisien</h5>
                                <p>Cukup share link undangan di aplikasi chat atau sosial media</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-image">
                                <img class="img-fluid" src="images/features-icon-2.svg" alt="alternative">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">Single Page Application</h5>
                                <p>Dengan desain responsive sangat cocok di akses via smartphone</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-image">
                                <img class="img-fluid" src="images/features-icon-6.svg" alt="alternative">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">Timer Countdown</h5>
                                <p>Hitung mundur dalam hari, jam, menit dan detik</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-image">
                                <img class="img-fluid" src="images/features-icon-4.svg" alt="alternative">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">Connect with Google</h5>
                                <p>Integrasi dengan Google Maps, Google Calendar dan Youtube</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-image">
                                <img class="img-fluid" src="images/features-icon-1.svg" alt="alternative">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">Send Messages and RSVP</h5>
                                <p>Fitur kirim ucapan dengan konfirmasi kehadiran</p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-image">
                                <img class="img-fluid" src="images/features-icon-3.svg" alt="alternative">
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">and More</h5>
                                <p>Wedding gift, autoplay backsound dan masih banyak lagi</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="basic-3">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="text-container">
                            <h2>Welcome Popup & Slider Image</h2>
                            <ul class="list-unstyled li-space-lg">
                                <li class="media">
                                    <i class="fas fa-chevron-right"></i>
                                    <div class="media-body">Sesuaikan popup welcome screen yang tertuju kepada nama penerima undangan</div>
                                </li>
                                <li class="media">
                                    <i class="fas fa-chevron-right"></i>
                                    <div class="media-body">Foto dengan slider caption yang bisa di kustomisasi</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="image-container">
                            <img class="img-fluid" src="images/2.popup.png" alt="alternative">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="basic-3">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7">
                        <div class="image-container">
                            <img class="img-fluid" src="images/4-gallery.png" alt="alternative">
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="text-container">
                            <h2>Gallery Photos and Video</h2>
                            <p>Share kenangan dan perjalanan cerita cinta kamu, dalam foto dan video YouTube</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="basic-4">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <div class="text-container">
                            <h2><font color="red">New Template</font> Instagram Style</h2>
                            <p>Desain baru ondangan website, dengan gaya tampilan ala-ala Instagram</p>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <div class="image-container">
                            <img class="img-fluid" src="images/3-newstyle.png" alt="alternative">
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
    export default {
        name: 'Features'
    }
</script>