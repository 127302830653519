<template>
    <div>
        <div class="footer">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h4>
                            ondangan.<span class="text-primary">website</span> adalah flatform situs web untuk menjangkau keluarga dan teman agar dapat menghadiri acara pernikahan kamu dan pasangan
                        </h4>
                        <div class="social-container">
                            <span class="fa-stack">
                                <a target="_blank" rel="noopener" href="https://instagram.com/ondangan.website">
                                    <i class="fas fa-circle fa-stack-2x"></i>
                                    <i class="fab fa-instagram fa-stack-1x"></i>
                                </a>
                            </span>
                            <span class="fa-stack">
                                <a target="_blank" rel="noopener" href="https://www.youtube.com/watch?v=0yMvjoZI9bg">
                                    <i class="fas fa-circle fa-stack-2x"></i>
                                    <i class="fab fa-youtube fa-stack-1x"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="copyright">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <ul class="list-unstyled li-space-lg p-small">
                            <li>
                                <a class="popup-with-move-anim" href="#details-lightbox">Credits & Attribution</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <p class="p-small statement">
                            Copyright © 2021
                            <a style="font-weight:bold;" href="https://ondangan.website">ondangan.website</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div id="details-lightbox" class="lightbox-basic zoom-anim-dialog mfp-hide">
            <div class="row">
                <button title="Close (Esc)" type="button" class="mfp-close x-button">x</button>
                <div class="col-lg-12">
                    <h3>Credits &amp; Attribution License</h3>
                    <hr>
                    <p>
                        All icons and media used on the https://ondangan.website domain and or on all subdomains are free for personal and commercial purpose with attribution. <br>
                        In this section, here is a list of the owners of the icons used.
                    </p>
                    <h4>Icon</h4>
                    <p>
                        Refer to the license certificate from <a target="_blank" rel="nooperner" href="https://flaticon.com">Flaticon</a> -> <a target="_blank" rel="nooperner" href="https://media.flaticon.com/license/license.pdf">https://media.flaticon.com/license/license.pdf</a>
                        <br> Here is a list of the owners of the icons used.
                    </p>
                    <ul class="list-unstyled li-space-lg">
                        <li class="media">
                            <i class="fas fa-chevron-right"></i><div class="media-body">
                                check.png -> Icons made by <a target="_blank" rel="nooperner" href="https://www.freepik.com" title="Freepik">Freepik</a> from <a target="_blank" rel="nooperner" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                            </div>
                        </li>
                        <li class="media">
                            <i class="fas fa-chevron-right"></i><div class="media-body">
                                menu.png -> Icons made by <a target="_blank" rel="nooperner" href="https://www.flaticon.com/authors/those-icons" title="Those Icons">Those Icons</a> from <a target="_blank" rel="nooperner" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                            </div>
                        </li>
                        <li class="media">
                            <i class="fas fa-chevron-right"></i><div class="media-body">
                                pin.png -> Icons made by <a target="_blank" rel="nooperner" href="https://www.flaticon.com/authors/vitaly-gorbachev" title="Vitaly Gorbachev">Vitaly Gorbachev</a> from <a target="_blank" rel="nooperner" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                            </div>
                        </li>
                        <li class="media">
                            <i class="fas fa-chevron-right"></i><div class="media-body">
                                facebook-messenger.png -> Icons made by <a target="_blank" rel="nooperner" href="" title="Grow studio">Grow studio</a> from <a target="_blank" rel="nooperner" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                            </div>
                        </li>
                        <li class="media">
                            <i class="fas fa-chevron-right"></i><div class="media-body">
                                play.png -> Icons made by <a target="_blank" rel="nooperner" href="https://www.flaticon.com/authors/ilham-fitrotul-hayat" title="Ilham Fitrotul Hayat">Ilham Fitrotul Hayat</a> from <a target="_blank" rel="nooperner" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                            </div>
                        </li>
                        <li class="media">
                            <i class="fas fa-chevron-right"></i><div class="media-body">
                                vecteezy_social-media-icon-pack_2557423 -> <a target="_blank" rel="noopener" href="https://www.vecteezy.com/free-vector/instagram-save-icon">Instagram Save Icon Vectors by Vecteezy</a>
                            </div>
                        </li>
                        <h4>Images</h4>
                        <li class="media">
                            <i class="fas fa-chevron-right"></i><div class="media-body">
                                wedding-isntagram-stories-template-theme_8476173 -> <a target="_blank" rel="noopener" href="https://www.freepik.com/psd/wedding">Wedding psd created by freepik - www.freepik.com</a>
                            </div>
                        </li>
                        <li class="media">
                            <i class="fas fa-chevron-right"></i><div class="media-body">
                                vector-golden-floral-seamless-pattern-retro-style -> <a target="_blank" rel="noopener" href='https://www.freepik.com/vectors/pattern'>Pattern vector created by macrovector - www.freepik.com</a>
                            </div>
                        </li>
                    </ul>
                    <!-- <h4>Sound</h4>
                    <ul class="list-unstyled li-space-lg">
                        <li class="media">
                            <i class="fas fa-chevron-right"></i><div class="media-body">
                                Backsound Music by -> <a target="_blank" rel="noopener" href="https://www.bensound.com">Bensound</a>
                            </div>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        mounted() {
           // Lightbox
            $('.popup-with-move-anim').magnificPopup({
                type: 'inline',
                fixedContentPos: true,
                fixedBgPos: true,
                overflowY: 'auto',
                closeBtnInside: true,
                preloader: false,
                midClick: true,
                removalDelay: 300,
                mainClass: 'my-mfp-slide-bottom'
            });
        }
    }
</script>

