<template>
    <div id="start">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'App',
        created() {
            window.addEventListener('contextmenu', function(e) {
                e.preventDefault();
            }, false);
        },
    }
</script>