<template>
	<div id="home">
        <nav class="navbar navbar-expand-lg fixed-top navbar-light d-none d-xl-block">
            <div class="container">
                <button class="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <a class="nav-link page-scroll" href="#header">Home <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link page-scroll" href="#features">Features</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link page-scroll" href="#demo">Demo</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link page-scroll" href="#pricing">Pricing</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link page-scroll" href="#download">Contact Us</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <Header/>
        <Features/>
        <Demo :showCounter="this.showCounter"/>
        <Pricing/>
        <Footer/>
	</div>
</template>

<script>
    import Header from './Header.vue'
    import Features from './Features.vue'
    import Demo from './Demo.vue'
    import Pricing from './Pricing.vue'
    import Footer from './Footer.vue'
    export default {
        name: 'Home',
        components: {
            Header,
            Features,
            Demo,
            Pricing,
            Footer,
        },
        data() {
            return {
                showCounter: false
            }
        },
        created() {
            let devMode = new URL(location.href).searchParams.get('dev')
            this.showCounter = devMode
        }
    }
</script>